.classic-btn {
  border: 0;
  border-radius: 88px;
  font-family: Roboto;
  font-weight: 600;
  transition: all .2s ease-in-out;
}

.classic-btn:hover {
  transform: scale(1.2);
}

.go-classic-btn {
  transition: all .2s ease-in-out;
}

.go-classic-btn:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 480px) {
  .classic-btn {
    padding: 16px 40px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .classic-btn {
    padding: 16px 60px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .classic-btn {
    padding: 16px 60px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .classic-btn {
    padding: 16px 60px;
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 1201px) {
  .classic-btn {
    padding: 16px 60px;
    font-size: 16px;
    line-height: 24px;
  }
}

/*# sourceMappingURL=index.70a63e44.css.map */
