.classic-btn {
  font-family: 'Roboto';
  font-weight: 600;
  border-radius: 88px;
  border: 0px;
  transition: all .2s ease-in-out;
}

.classic-btn:hover {
  transform: scale(1.2);
}
.go-classic-btn {
  transition: all .2s ease-in-out;
}
.go-classic-btn:hover {
  transform: scale(1.2);
}

/* Mobile */
@media only screen and (max-width: 480px) {
  .classic-btn {
    font-size: 14px;
    line-height: 20px;
    padding: 16px 40px;
  }
}

/* Tablets */
@media only screen and (min-width: 481px) and (max-width:768px) {
  .classic-btn {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 60px;
  }
}

/* Small screens */
@media only screen and (min-width: 769px) and (max-width:1024px) {
  .classic-btn {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 60px;
  }
}

/* Large screens */
@media only screen and (min-width: 1025px) and (max-width:1200px) {
  .classic-btn {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 60px;
  }
}

/* Extra large screens */
@media only screen and (min-width: 1201px) {
  .classic-btn {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 60px;
  }
}